import React from "react";
import { graphql, Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import MetaTags from "../components/MetaTags";
import Container from "../components/Container";
import styled from "styled-components";

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      frontmatter {
        title
        summary
        slug
        image {
          ...ImageFields
        }
      }
      body
    }
  }
`;

const PortfolioWrapper = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;

export default function PortfolioTemplate({
  data: { mdx: portfolio },
  pageContext,
}) {
  return (
    <PortfolioWrapper>
      <MetaTags
        title={portfolio.frontmatter.title}
        description={portfolio.frontmatter.summary}
      />
      <Container>
        <h1>{portfolio.frontmatter.title}</h1>
        <MDXRenderer>{portfolio.body}</MDXRenderer>
        <Link to="/portfolio">Back to portfolio</Link>
      </Container>
    </PortfolioWrapper>
  );
}
